import Alpine from "alpinejs";
import htmx from "htmx.org";
import intersect from "@alpinejs/intersect";
import focus from "@alpinejs/focus";
import mask from "@alpinejs/mask";

window.Alpine = Alpine;
window.htmx = htmx;

Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(mask);

if (window.htmx) {
	import("htmx-ext-head-support").then(() => {
		console.log("htmx-ext-head-support loaded");
	});
}

if (nonce !== undefined) {
	document.addEventListener("htmx:configRequest", function (event) {
		event.detail.headers["X-WP-Nonce"] = nonce;
	});
}

document.addEventListener("DOMContentLoaded", function () {
	Alpine.start();
});
